@import url("./colors.css");
.topbar-actions {
  margin: 2px 0 0 20px;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.65;
  font-weight: bold;
}
.topbar-actions:hover {
  opacity: 1;
}
.scoring-table {
  float: right;
  margin-left: 10px;
  text-align: center;
  border-bottom: 1px solid #666;
}
.scoring-example {
  height: 165px;
}
.stacked-set {
  display: inline-block;
  position: relative;
  width: 80px;
}
.small-card {
  position: absolute;
  height: 70px;
  width: 50px;
  border: 1px solid #000;
  line-height: 70px;
  text-align: center;
}
