.player-card {
  margin: 10px 0 20px 0;
  min-height: 110px;
  width: 250px;
}
.player-name {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}
.player-title {
  max-width: 200px;
  overflow: hidden;
}
.player-edit {
  opacity: 0.65;
}
.player-edit:hover {
  cursor: pointer;
  opacity: 1;
}
.mdc-theme--disabled {
  background: lightgrey;
}
