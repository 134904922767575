.config-container {
  text-align: center;
  max-width: 400px;
}

.radio-label {
  margin: 5px 0;
}

.input-name {
  width: 240px;
  margin: 20px 0;
}
