.wild {
  background: linear-gradient(
    135deg,
    #ff7c00 0%,
    #0f00ed 33%,
    #f4f404 66%,
    #f4f404 66%,
    #9d00b2 100%
  );
}
.plus2 {
  background-color: lightblue;
}
.orange {
  background-color: orange;
}
.blue {
  background-color: blue;
}
.brown {
  background-color: saddlebrown;
}
.yellow {
  background-color: yellow;
}
.grey {
  background-color: grey;
}
.green {
  background-color: green;
}
.pink {
  background-color: hotpink;
}
.empty {
  background-color: ghostwhite;
}
