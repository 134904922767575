@import url("./colors.css");
.game-container {
  display: flex;
  justify-content: flex-start;
  min-width: 800px;
  max-width: 1024px;
}
.game-container .players-container {
  padding: 20px 15px 10px 20px;
}

.new-game {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.75rem;
  background: #6200ee;
  color: white;
  padding: 5px;
  width: 250px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.information-action {
  font-size: 0.75rem;
  background: var(--mdc-theme-secondary, #018786);
  color: white;
  padding: 10px 10px 15px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 225px;
}
.information-action i {
  position: relative;
  top: 5px;
  padding-right: 5px;
}

.content-container {
  width: 100%;
  min-width: 350px;
  max-width: 400px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.information-container {
  margin-bottom: 20px;
  display: flex;
  max-width: 350px;
}
.information {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.next-player {
  font-size: 1.5rem;
}
.last-round {
  color: #b00020;
}

.chosen-card-container {
  margin-top: 20px;
  min-width: 250px;
  padding-right: 20px;
}
.chosen-card {
  width: 150px;
  height: 200px;
  border: 1px solid #999;
  margin-left: 50px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chosen-card.plus2::after {
  content: "+2";
  font-size: 2rem;
  text-align: center;
}
