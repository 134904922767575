@import url("./colors.css");
.house-row {
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  max-width: 350px;
  justify-content: space-between;
}
.house-row.empty {
  background: inherit;
}
.house-row.unavailable,
.house-row.empty,
.placing-card .house-row.full {
  cursor: not-allowed;
}
.placing-card .house-row.empty {
  cursor: pointer;
}
.placing-card .house-row.full:hover {
  outline: 0;
}
.house-row.available:hover,
.placing-card .house-row.empty:hover,
.house-row.full:hover {
  outline: #6200ee solid 4px;
}
.two-players .house-row {
  justify-content: flex-start;
}
.house-card {
  width: 75px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
  outline: 1px solid #999;
}
.house-marker {
  font-size: 2.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.empty .house-marker,
.available .house-marker,
.full .house-marker {
  background: burlywood;
  color: #666;
}
.unavailable .house-marker {
  background: #333;
  color: #ccc;
}
.house-card.empty {
  outline: 1px dashed #999;
}
.house-card.plus2::after {
  content: "+2";
  font-size: 2rem;
  text-align: center;
}
