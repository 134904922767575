.hand-container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  overflow-y: scroll;
}

.card-set {
  margin-right: 15px;
}

.card-set:hover {
  outline: 1px solid lightgrey;
}

.card {
  height: 40px;
  width: 25px;
  border: thin solid #666;
  display: inline-block;
  margin: 3px;
  color: black;
  line-height: 40px;
  text-align: center;
}

.card-set.negative .card {
  border: thin solid red;
}
